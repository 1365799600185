/*      HEADER              */
header, .fixedmenu{
    position: relative;
    z-index: 999;
    .left_menu{
        width:64px;
        position: fixed;
        margin-left:-64px;
        top:0px;
        background-color: #131414;
        z-index: 99;
        .open_button{
            text-align: center;
            height:62px;
            .burger_icon{
                width:26px;
                padding-top: 6px;
                padding-bottom: 3px;
                margin:16px 0px 0px 0px;
                span{
                    display: block;
                    height: 3px;
                    margin-bottom: 4px;
                    background-color: $bgWhite;
                    border-radius: 3px;
                    @extend .trn;
                }
                &:hover{
                    span{
                        background-color: $bgRed;
                    }
                }
            }
        }
        .bar{
            position: relative;
            ul{
                li.main{
                    padding-top: 6px;
                    padding-bottom: 6px;
                    text-align: center;
                    position: relative;
                    @extend .trn;
                    &.social{
                        padding-top: 4px;
                        padding-bottom: 3px;
                    }
                    &.big{
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    &:hover{
                        background-color: #202020;
                    }
                    .icons{
                        width:46px;
                        height:34px;
                        position: relative;
                        @include bg_img_pos(no-repeat, center, auto);
                        &.home{
                            background-image: url(../img/icons/home.png);
                        }
                        &.about{
                            background-image: url(../img/icons/about.png);
                        }
                        &.categories{
                            background-image: url(../img/icons/categories.png);
                        }
                        &.products{
                            background-image: url(../img/icons/products.png);
                        }
                        &.categories, &.products{
                            &::after{
                                @extend .beforeAfter;
                                top:0px;
                                right:0px;
                                height: 100%;
                                width:8px;
                                @include bg_img_pos(no-repeat, right center, auto);
                                background-image: url(../img/icons/menu_arrow.png);
                            }
                        }
                        &.fb{
                            background-image: url(../img/icons/fb.png);
                        }
                        &.insta{
                            background-image: url(../img/icons/ins.png);
                        }
                    }
                    .icons_big{
                        width:46px;
                        height:46px;
                        border-radius: 10px;
                        @include bg_img_pos(no-repeat, center, auto);
                        &.star{
                            background-color: $bgRed;
                            background-image: url(../img/icons/star.png);
                            margin-top: 22px;
                        }
                        &.local{
                            background-color: #2a2929;
                            background-image: url(../img/icons/local.png);
                        }
                        &.chat{
                            background-color: #3c3c3c;
                            background-image: url(../img/icons/chat.png);
                        }
                    }
                    .long_link{
                        opacity: 0;
                        visibility: hidden;
                        background-color: #151515;
                        width: 260px;
                        height: 100%;
                        position: absolute;
                        left:100%;
                        top:0px;
                        @extend .trn;
                        a{
                            padding-left: 10px;
                            padding-top: 13px;
                            font-size: 18px;
                            display: block;
                            width: 100%;
                            height: 100%;
                            box-sizing: border-box;
                            @extend .trn;
                            &.star{
                                margin-top: 22px;
                                height: calc(100% - 22px);
                            }
                            &.star, &.local, &.chat{
                                padding-top: 15px;
                            }
                            &.social{
                                padding-top: 11px;
                            }
                        }
                        &:hover{
                            background-color: #202020;
                            a{
                                color: $colorRed;
                            }
                        }
                        &.has_submenu{
                            ul.submenu{
                                visibility: hidden;
                                opacity: 0;
                                background-color: #202020;
                                width: 240px;
                                position: absolute;
                                left:100%;
                                top:-130px;
                                padding-bottom: 10px;
                                @extend .trn;
                                &.products{
                                    top:-45px;
                                }
                                li{
                                    a{
                                        display: block;
                                        height: 40px;
                                        padding-left: 70px;
                                        box-sizing: border-box;
                                        padding-top: 9px;
                                        font-size: 18px;
                                        color: #fff;
                                        @include bg_img_pos(no-repeat, 40px center, auto 23px);
                                        @extend .trn;
                                        &:hover{
                                            background-color: #353535;
                                        }
                                    }
                                    span{
                                        display: block;
                                        height: 45px;
                                        padding-left: 40px;
                                        padding-top: 13px;
                                        box-sizing: border-box;
                                        font-size: 24px;
                                        color: $colorRed;
                                    }
                                    &.has_subcateg{
                                        position: relative;
                                        ul.subcategs{
                                            visibility: hidden;
                                            opacity: 0;
                                            background-color: #353535;
                                            width: 240px;
                                            position: absolute;
                                            left:100%;
                                            top:-40px;
                                            padding-bottom: 10px;
                                            @extend .trn;
                                            li{
                                                a{
                                                    padding-left: 40px;
                                                    &:hover{
                                                        background-color: #555;
                                                    }
                                                }
                                            }
                                        }
                                        &:hover, &.on{
                                            ul.subcategs{
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }
                            }
                            &:hover, &.on{
                                ul.submenu{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
            .logo_menu{
                position: absolute;
                opacity: 0;
                visibility: hidden;
                background-color: #151515;
                width: 260px;
                height: 62px;
                top: -62px;
                left:100%;
                @extend .trn;
                img{
                    margin-left: 60px;
                    margin-top: 5px;
                    max-height: 52px;
                }
            }
            &:hover, &.on{
                ul{
                    li.main{
                        .long_link{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                .logo_menu{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }   //  END LEFT MENU

    .top_bar{
        grid-template-columns: 1fr 1fr 1fr;
        height:145px;
        z-index: 20;
        position: relative;
    }
    .search_bar{
        p.search_text{
            margin-top: 30px;
            margin-left: 8px;
            color: $colorRed;
            font-size: 23px;
        }
        .search_form{
            display: block;
            width: 100%;
            margin-top: 15px;
            position: relative;
            .input_text{
                display: block;
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                background-color: #f0f0f0;
                border-radius: 20px;
                padding-left: 18px;
                font-size: 16px;
                color: #777;
            }
            .submit{
                position: absolute;
                top:5px;
                right:10px;
                width:30px;
                height:30px;
                background-image: url(../img/icons/search.png);
                @include bg_img_pos(no-repeat, center center, 16px auto);
            }
        }
    }

    .phone_bar{
        .fr{
            padding-right: 40px;
            padding-left: 40px;
            position: relative;
            height: 100%;
            .full_address{
                opacity: 0;
                visibility: hidden;
                background-color: #1e1e1e;
                width: 100%;
                position: absolute;
                left:0px;
                top:100%;
                @extend .trn;
                .add_title{
                    font-size: 14px;
                    color: #ff0010;
                    text-align: center;
                    text-transform: uppercase;
                    padding-top: 10px;
                    font-family: $futuraMdFont;
                    font-weight: bold;
                    span{
                        display: inline-block;
                        padding-top: 35px;
                        padding:25px 35px 3px 35px;
                        @include bg_img_pos(no-repeat, right top, auto);
                        background-image: url(../img/icons/local_footer.png);
                    }
                }
                .add_text{
                    font-size: 14px;
                    font-family: $futuraMdFont;
                    text-align: center;
                    padding: 0px 30px 45px 30px;
                    a{
                        @extend .trn;
                        &:hover{
                            color: $colorRed;
                        }
                    }
                }
                .hour_title{
                    font-size: 18px;
                    color: #ff0010;
                    text-align: center;
                    font-family: $futuraMdFont;
                }
                .hour_text{
                    font-size: 14px;
                    font-family: $futuraMdFont;
                    text-align: center;
                    padding: 3px 30px 40px 30px;
                    line-height: 135%;
                }
            }
            &:hover{
                .full_address{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        
        .title{
            font-size: 20px;
            margin-top: 25px;
        }

        .phone{
            font-size: 20px;
            padding-left: 42px;
            line-height: 180%;
            @include bg_img_pos(no-repeat, left 10px, auto);
            &.tel{
                background-image: url(../img/icons/phone.png);
            }
            &.whats{
                background-image: url(../img/icons/whats.png);
                background-position: 4px 10px;
            }
            span{
                font-size: 36px;
            }
            a{
                @extend .trn;
                &:hover{
                    color: $colorRed;
                }
            }
        }

        .fav_btn{
            padding:10px 0px 0px 50px;
            font-size: 20px;
            margin-top: 70px;
            @include bg_img_pos(no-repeat, left top, auto);
            background-image: url(../img/icons/favorite.png);
        }

    }

    .top_categs{
        ul{
            background-color: $bgRed;
            height: 88px;
            position: relative;
            li.item{
                flex: 1;
                @extend .trn;
                display: flex;
                align-items: flex-end;
                a{
                    display: block;
                    @include bg_img_pos(no-repeat, center calc(50% - 25px), auto);
                    position: relative;
                    padding-left: 10px;
                    padding-right: 10px;
                    text-align: center;
                    min-width: 80px;
                    width:100%;
                    padding-bottom: 8px;

                    .icon{
                        margin-top: 10px;
                    }

                    span{
                        text-align: center;
                        font-size: 14px;
                        text-transform: uppercase;
                        display: block;
                        padding-top: 5px;
                    }
                }
                &:hover{
                    background-color: #750209;
                }
            }
            li.item + li.item{
                &::before{
                    @extend .beforeAfter;
                    top:13px;
                    width:1px;
                    height: 62px;
                    @include bg_img_pos(no-repeat, left top, auto);
                    background-image: url(../img/icons/decoration.png);
                }
            }
        }
    }

    h1.logo{
        @include bg_img_pos(no-repeat, left top, auto);
        background-image: url(../img/djor-erotik-logo.png);
        width:220px;
        height: 105px;
        margin:20px 0px 0px 27px;
    }

    ul.main_menu{
        li.mm_item{
            float:left;
            a.mm_link{

            }
        }
    }
    
    ul.sub_menu{
        li.sm_item{
            float:left;
            a.sm_link{

            }
        }
    }
}

.fixedmenu{
    position: fixed;
    left:0px;
    top: 0px;
    width: 100%;
    z-index: 995;
    background-color: #000;
	@include transition(all 0.5s);
    @include translate(0%,-100%);
    &.on{
        @include translate(0%,0%);
    }
    .top_bar {
        height: 82px;
    }
    h1.logo{
        background-size: 100% auto;
        width: 130px;
        height: 65px;
        margin: 10px 0 0 80px;
    }
    .search_bar{
        p.search_text{
            display: none;
        }
        .search_form {
            margin-top: 22px;
        }
    }
    .phone_bar{
        .fr{
            padding-right: 70px;
            .full_address{
                .add_title {
                    font-size: 12px;
                    span{
                        padding: 16px 25px 3px;
                        background-size: 20px auto;
                    }
                }
                .add_text {
                    padding: 0 10px 25px;
                }
                .hour_title {
                    font-size: 16px;
                }
                .hour_text {
                    padding: 3px 30px 25px;
                }
            }
        }
        .title {
            font-size: 12px;
            margin-top: 8px;
        }
        .phone {
            font-size: 12px;
            padding-left: 28px;
            background-position: left 3px !important;
            background-size: auto 18px !important;
            &.whats{
                background-position: 2px 3px !important;
            }
            span {
                font-size: 22px;
            }
        }
    }
    .top_categs ul {
        height: 45px !important;
        li.item{
            padding-top: 2px;
            align-items: center;
        }
        li.item + li.item::before {
            top: 8px;
            height: 32px;
        }
    }
}

//  RESPONSIVE
@media (max-width: 1299px){
    header, .fixedmenu{
        .top_categs{
            ul{
                li.item{
                    a{
                        padding-left: 5px;
                        padding-right: 5px;
                        min-width: 60px;
                        span{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1169px){
    header, .fixedmenu{
        .top_bar {
            height: 102px;
        }
        .left_menu {
            margin-left: 0px;
            .bar {
                display: none;
                &.on{
                    display: block;
                }
            }
        }
        .top_categs{
            nav{
                overflow-x: auto;
                overflow-y: hidden;
            }
            ul{
                li.item{
                    background-color: #a2030d;
                    a{
                        padding-left: 10px;
                        padding-right: 10px;
                        min-width: 70px;
                        span{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        h1.logo, h1.logo {
            background-size: 100% auto;
            width: 130px;
            height: 65px;
            margin: 20px 0 0 80px;
        }
        .search_bar{
            .search_form {
                margin-top: 8px;
            }
            p.search_text {
                margin-top: 15px;
                font-size: 18px;
            }
        }
        .phone_bar{
            .fr {
                padding-right: 20px;
                padding-left: 20px;
            }
            .title {
                font-size: 18px;
                margin-top: 12px;
            }
            .phone.tel {
                background-size: 24px auto;
                margin-top: -2px;
            }
            .phone.whats {
                background-position: 3px 10px;
                background-size: 20px auto;
                margin-top: -8px;
            }
            .phone {
                font-size: 18px;
                padding-left: 35px;
                span {
                    font-size: 28px;
                }
            }
        }
    }
    

}

@media (max-width: 1023px){
    header .left_menu .bar{
        ul li.main .long_link {
            width: 120px;
            &.has_submenu ul.submenu li{
                a {
                    padding-left: 60px;
                    background-position: 25px;
                }
                span {
                    padding-left: 25px;
                }
                &.has_subcateg ul.subcategs{
                    width: 200px;
                    li a {
                        padding-left: 25px;
                    }
                }
            }
        }
        .logo_menu {
            width: 120px;
            img {
                margin-left: 14px;
                margin-top: 10px;
                max-height: 44px;
            }
        }
    }
    .mobile_menu_mask{
        display: block;
        position: absolute;
        left:0px;
        top:0px;
        height: 100%;
        width: 100%;
        z-index: 10;
        cursor: pointer;
        &.arrow{
            @include bg_img_pos(no-repeat, center, 15px auto);
            background-image: url(../img/svg/arrow_right_menu.svg);
            width: 40px;
            left:auto;
            right:0px;
        }
    }
    .top_categs{
        position: relative;
        z-index: 5;
    }
}

@media (max-width: 767px){
    header, .fixedmenu{
        .top_bar {
            grid-template-columns: calc(58% - 30px) 9% 33%;
            height: 82px;
        }
        .top_categs{
            ul{
                height: 80px;
                li.item{
                    a{
                        span{
                            padding-top: 2px;
                        }
                        .icon {
                            scale: 0.8;
                        }
                    }
                }
            }
        }
        h1.logo, h1.logo {
            width: 100px;
            height: 50px;
            margin: 17px 0 0 80px;
        }
        .search_lightbox{
            display: none;
        }
        .phone_bar{
            .fr {
                padding-right: 0px;
                padding-left: 0px;
                float: none;
                .full_address {
                    display: none;
                }
            }
            .title {
                font-size: 16px;
                margin-top: 7px;
            }
            .phone.tel {
                background-size: 20px auto;
                background-position: left 8px;
            }
            .phone.whats {
                background-position: 2px 8px;
                background-size: 16px auto;
                margin-top: -5px;
            }
            .phone {
                font-size: 16px;
                padding-left: 25px;
                span {
                    font-size: 16px;
                }
            }
        }
    }
    /*.left_menu .bar ul li.main .long_link{
        &.offmobile{
            width: 0px;
            .categs_menu.arrow{
                display: none;
            }
        }
        &.has_submenu ul.submenu {
            z-index: 20;
            li a{
                overflow: hidden;
            }
            &.offmobile{
                width: 60px;
                li.has_subcateg{
                    .mobile_menu_mask.arrow{
                        width: 100%;
                        background-image: none;
                    }
                }
            }
            li.has_subcateg ul.subcategs {
                width: 190px;
            }
        }
    }*/

    //  NEW MENU MOBILE
    header .left_menu{
        display: none !important;
        &.mobilefix{
            display: block !important;
        }
        &.on {
            position: fixed;
            top:0px;
            left:0px;
            width:62px;
            height:62px;
            &.on{
                width:100vw;
                height:100vh;
                overflow-y: auto;
            }
        }
        .open_button {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 999;
            width: 64px;
        }
        .bar{
            .logo_menu {
                top: 0px;
                left: 64px;
            }
            ul.mobile_bar{
                padding-top: 62px;
                padding-bottom: 40px;
                li.main{
                    display: flex;
                    .mobile_menu_mask {
                        &.maskicon{
                            height:46px;
                            width: 64px;
                        }
                        &.mask_link{
                            height:34px;
                            width: 30px;
                        }
                        &.subcateg_menu{
                            height: 36px;
                        }
                    }
                    .icons {
                        width: 64px;
                    }
                    .icons_big {
                        margin-left: 9px;
                        margin-right: 9px;
                    }
                    .long_link {
                        position: relative;
                        left: auto;
                        height: auto;
                        background-color: transparent;
                        a {
                            padding-top: 7px;
                            height: 34px;
                        }
                        &.has_submenu ul.submenu{
                            display: none;
                            li.has_subcateg ul.subcategs {
                                display: none;
                                position: relative;
                                left: 25px;
                                top: 0px;
                                background-color: transparent;
                                li a {
                                    height: 36px;
                                    padding-top: 7px;
                                }
                            }
                        }
                        &.has_submenu.on ul.submenu{
                            display: block;
                            opacity: 1;
                            visibility: visible;
                            position: relative;
                            left: 0px;
                            top: 0px;
                            background-color: transparent;
                            width: 250px;
                            span{
                                display: none;
                            }
                            li a {
                                padding-left: 50px;
                                background-position: 15px;
                            }
                            li.has_subcateg.on ul.subcategs{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }


}


@media (max-width: 339px){
    .fixedmenu .phone_bar .title, header .phone_bar .title {
        font-size: 14px;
    }
    .fixedmenu .phone_bar .phone, header .phone_bar .phone {
        font-size: 16px;
        padding-left: 0px !important;
        background-image: none !important;
    }
    .fixedmenu h1.logo, header h1.logo {
        width: 80px;
        height: 50px;
        margin: 17px 0 0 75px;
    }
}
/*      END HEADER          */