//      EXTERNAL FONTS

@font-face{
	font-family:'Futura Extra';
	src: url("../fonts/futura-extra-black-bt.eot"), url("../fonts/futura-extra-black-bt.ttf") format("truetype"), url("../fonts/futura-extra-black-bt.otf");
	font-weight:900;   
	font-style:normal;
}
@font-face{
	font-family:'Futura Cond';
	src: url("../fonts/futura-light-condensed-bt.eot"), url("../fonts/futura-light-condensed-bt.ttf") format("truetype"), url("../fonts/futura-light-condensed-bt.otf");
	font-weight:300;   
	font-style:normal;
}
@font-face{
	font-family:'Futura Cond';
	src: url("../fonts/futura-medium-condensed-bt.eot"), url("../fonts/futura-medium-condensed-bt.ttf") format("truetype"), url("../fonts/futura-medium-condensed-bt.otf");
	font-weight:400;
	font-style:normal;
}
@font-face{
	font-family:'Futura Cond';
	src: url("../fonts/futura-medium-condensed-bt-italic.eot"), url("../fonts/futura-medium-condensed-bt-italic.ttf") format("truetype"), url("../fonts/futura-medium-condensed-bt-italic.otf");
	font-weight:400;
	font-style:italic;
}
@font-face{
	font-family:'Futura Cond';
	src: url("../fonts/futura-bold-condensed-bt.eot"), url("../fonts/futura-bold-condensed-bt.ttf") format("truetype"), url("../fonts/futura-bold-condensed-bt.otf");
	font-weight:700;
	font-style:normal;
}
@font-face{
	font-family:'Futura Cond';
	src: url("../fonts/futura-extra-black-condensed-bt.eot"), url("../fonts/futura-extra-black-condensed-bt.ttf") format("truetype"), url("../fonts/futura-extra-black-condensed-bt.otf");
	font-weight:900;
	font-style:normal;
}
@font-face{
	font-family:'Gando';
	src: url("../fonts/gando-bt.eot"), url("../fonts/gando-bt.ttf") format("truetype"), url("../fonts/gando-bt.otf");
	font-weight:normal;   
	font-style:normal;
}
@font-face{
	font-family:'Sweengly';
	src: url("../fonts/sweengly.eot"), url("../fonts/sweengly.ttf") format("truetype"), url("../fonts/sweengly.otf");
	font-weight:normal;   
	font-style:normal;
}
@font-face{
	font-family:'Futura MD';
	src: url("../fonts/Futura-md.eot"), url("../fonts/Futura-md.ttf") format("truetype"), url("../fonts/Futura-md.otf");
	font-weight:normal;
	font-style:normal;
}
@font-face{
	font-family:'Futura MD';
	src: url("../fonts/Futura-md-bold.eot"), url("../fonts/Futura-md-bold.ttf") format("truetype"), url("../fonts/Futura-md-bold.otf");
	font-weight:bold;
	font-style:normal;
}
@font-face{
	font-family:'Great Vibes';
	src: url("../fonts/GreatVibes-Regular.eot"), url("../fonts/GreatVibes-Regular.ttf") format("truetype"), url("../fonts/GreatVibes-Regular.otf");
	font-weight:normal;
	font-style:normal;
}
@font-face{
	font-family:'Futura BT';
	src: url("../fonts/futura-light-bt.eot"), url("../fonts/futura-light-bt.ttf") format("truetype"), url("../fonts/futura-light-bt.otf");
	font-weight:300;
	font-style:normal;
}
 

//      SASS VARIABLES
$defaultFont: 'Futura Cond';
$futuraMdFont: 'Futura MD';
$futuraBtFont: 'Futura BT';
$greatVibesFont: 'Great Vibes';
$sweenglyFont: 'Sweengly';
$titleColor: #000000;
$textColor: #555555;
$textColorLight: #777777;
$borderGray: #606060;
$colorWhite:#fff;
$bgWhite:#fff;
$colorRed:#c2040f;
$colorRedDark:#881213;
$bgRed:#a2030d;
$bgRedDark:#661213;


/*
mixin = include

@include transition(all 0.3s);
@include translate(var_x,var_y);
@include bg_img_pos(no-repeat, position, size);


extend = classes

@extend .nome_da_classe;

*/