footer.site_footer{
    background-color: #2a2a2a;
    padding-top: 20px;
    padding-bottom: 20px;
    .grid_footer{
        grid-template-columns: calc(67% - 20px) 33%;
        background-image: url(../img/temp/footer_bg.png);
        @include bg_img_pos(no-repeat, right bottom, auto);
        padding-left: 25px;
        padding-right: 25px;
        .left{
            flex-wrap: nowrap;
        }
        .right{
            flex-wrap: nowrap;
        }
        .item{
            padding-right: 40px;
            &.last{
                padding-right: 0px;
            }
            .title, h4{
                font-size: 14px;
                color: #ff0010;
                text-transform: uppercase;
                margin-bottom: 5px;
                font-family: $futuraMdFont;
                font-weight: bold;
                &.small{
                    text-transform: none;
                    margin-top: 15px;
                }
            }
            h3{
                font-size: 14px;
                color: #ff0010;
                margin-bottom: 5px;
                font-family: $futuraMdFont;
                font-weight: bold;
                text-transform: none;
                margin-top: 15px;
            }
            p, li{
                font-family: $futuraMdFont;
                font-size: 14px;
                line-height: 140%;
                a:hover{
                    color: $colorRed;
                }
            }
            &.local{
                padding-left: 35px;
                @include bg_img_pos(no-repeat, left top, auto);
                background-image: url(../img/icons/local_footer.png);
            }
            .social_icon{
                width:38px;
                height:38px;
                @include bg_img_pos(no-repeat, center top, auto);
                &.fb{
                    background-image: url(../img/icons/fb_footer.png);
                }
                &.ins{
                    background-image: url(../img/icons/ins_footer.png);
                }
                &:hover{
                    opacity: 0.7;
                }
            }
            .backtop{
                width:44px;
                height: 44px;
                @include bg_img_pos(no-repeat, right top, auto);
                background-image: url(../img/icons/backtop.png);
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }
}

@media (max-width: 1169px){
    footer.site_footer .grid_footer {
        grid-template-columns: 1fr;
        .right {
            padding-top: 20px;
        }
    }
}

@media (max-width: 767px){
    footer.site_footer .grid_footer{
        .left{
            display: block;
        }
        .right {
            padding-top: 0px;
            display: block;
            .item{
                .alcenter{
                    text-align: left;
                }
            }
        }
        .item {
            padding-right: 0px;
            padding-bottom: 35px;
        }
    }
}