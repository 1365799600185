@import "utils/reset";
@import "utils/variables";
@import "utils/base";
@import "common/header";

/*.realeases_box{
    @import "materialize.min.css";
}*/

//  INTRO
.fullbox{
    z-index:10;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    @extend .trns;
    &.fixed{
        position: fixed;
    }
    &.abs{
        position: absolute;
    }
    &.off{
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    }
    .container{
        height:100%;
        background-image: url(../img/temp/welcome-woman.png);
        background-position: left bottom;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .welcome_text{
            padding-right: 50px;
            img{
                display:block;
            }
            .loginbtn{
                margin-left: 100px;
                margin-top: 60px;
                cursor:pointer;
            }
        }
    }
}
#intro{
    z-index: 99999999999999 !important;
    background-color:#000;
}

//  HOME
.container{
    @extend .auto;
    max-width: 1140px;
}

.main_slider{
    height: auto;
    .item{
        height: 100%;
        .tempimg{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.timer_banner{
    background-color: #e3e1e1;
    height: 100px;
    margin-bottom: 35px;
    .left{
        grid-template-columns: 28% 72%;
        align-items: center;
        figure{
            text-align: center;
            max-height: 100%;
            max-width: 100%;
        }
        .text{
            .main{
                text-align: right;
                color: $colorRedDark;
                font-size: 26px;
                text-transform: uppercase;
                span{
                    font-size: 36px;
                }
            }
            .secondary{
                text-align: right;
                color: $textColorLight;
                font-size: 18px;
                margin-top: -3px;
            }
        }
    }
    .right{
        grid-template-columns: calc(60% - 20px) 40%;
        align-items: center;
        .time_box{
            justify-items: flex-end;
            grid-column-gap: 5px;
            .item{
                background-color: $bgWhite;
                height: 70px;
                width: 70px;
                border-radius: 10px;
                padding-top: 10px;
                box-sizing: border-box;
                .number, .text{
                    color: $colorRedDark;
                    text-align: center;
                }
                .number{
                    font-size: 30px;
                    line-height: 100%;
                }
                .text{
                    font-size: 18px;
                }
            }
        }
        .time_button{
            border-radius: 12px;
            padding:10px 35px 10px;
            background-color: $bgRed;
            font-size: 25px;
            text-transform: uppercase;
            @extend .trn;
            &:hover{
                background-color: #d71c28;
            }
        }
    }
}

.tabs_banner{
    flex-wrap: nowrap;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: hidden;
    margin-bottom: 30px;
    .item{
        height: 245px;
        width: 75px;
        margin-left: -10px;
        @extend .trn;
        .tab{
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            height: 100%;
            padding-left: 10px;
            cursor: pointer;
            .number{
                height: 65px;
                font-size: 48px;
                text-align: center;
                @extend .trn;
            }
            .title{
                height: calc(100% - 65px);
                box-sizing: border-box;
                padding-top: 15px;
                @extend .trn;
                h3{
                    transform: rotate(-90deg);
                    font-size: 36px;
                    text-transform: uppercase;
                    text-align: right;
                    margin-top: 60px;
                }
            }
            &:hover{
                .number{
                    color: #121212;
                }
                .title{
                    color: #121212;
                }
            }
        }
        .content{
            display: none;
            height: 100%;
            position: relative;
            margin-left: -10px;
            .header{
                background-color: $bgRed;
                height: 65px;
                border-top-right-radius: 12px;
                padding-left: 10px;
                p{
                    text-align: center;
                    text-transform: uppercase;
                    padding-top: 2px;
                    font-size: 48px;
                    text-align: center;
                    padding-top: 5px;
                }
            }
            .info{
                border-bottom-right-radius: 12px;
                height: calc(100% - 65px);
                align-items: center;
                background-color: #fff;
                grid-template-columns: calc(40% - 40px) 30% 30%;
                padding-left: 10px;
                .left{
                    p{
                        font-size: 24px;
                        color: $textColorLight;
                        text-align: center;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
                .center{
                    figure{
                        text-align: center;
                        img{
                            max-width: 100%;
                        }
                    }
                    p{
                        font-size: 30px;
                        color: $bgRed;
                        text-align: center;
                    }
                }
                .right{
                    padding-bottom: 40px;
                    p{
                        font-size: 20px;
                        color: $textColorLight;
                        text-align: center;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }
            .tab_plus_btn{
                position: absolute;
                right:15px;
                bottom: 10px;
                padding:0px 15px 0px 15px;
                height: 50px;
                font-size: 25px;
                background-color: $bgRed;
                border-radius: 12px;
                text-transform: uppercase;
                @extend .trn;
                &:hover{
                    background-color: #d71c28;
                }
            }
        }
        &.on{
            width: auto;
            margin-left: 0px;
            .tab{
                display: none;
            }
            .content{
                display: block;
            }
        }
        &.i1{
            position: relative;
            z-index: 10;
            .tab{
                background-color: #f16972;
            }
        }
        &.i2{
            position: relative;
            z-index: 8;
            .tab{
                background-color: #eb4e58;
            }
        }
        &.i3{
            position: relative;
            z-index: 6;
            .tab{
                background-color: #d71c28;
            }
        }
        &.i4{
            position: relative;
            z-index: 4;
            .tab{
                background-color: #a2030d;
            }
        }
        &.i5{
            position: relative;
            z-index: 2;
            .tab{
                background-color: #750209;
            }
        }
        &.i6{
            position: relative;
            z-index: 1;
            .tab{
                background-color: #4e0005;
            }
        }
    }
}
.best_sellers_bar{
    margin-bottom: 45px;
    ul.best_sell_list{
        align-items: center;
        justify-content: center;
        min-height: 190px;
        li{
            a{
                width:106px;
                height:106px;
                display: block;
                border-radius: 50%;
                box-sizing: border-box;
                @extend .trns;
                margin-left: 5px;
                margin-right: 5px;
                &.itens{
                    border:4px solid $bgWhite;
                    box-shadow: 0px 0px 3px 0px #ddd !important;
                    figure{
                        border:4px solid $borderGray;
                        background-color: $bgWhite;
                        box-shadow: 0px 0px 2px 0px #444 !important;
                    }
                }
                figure{
                    border-radius: 50%;
                    box-sizing: border-box;
                    width:100%;
                    height:100%;
                    overflow: hidden;
                    @extend .trns;
                    img{
                        @extend .imgcontain;
                    }
                    &.title{
                        img{
                            background-color: $bgWhite;
                            border-radius: 50%;
                            padding: 7px;
                            box-sizing: border-box;
                            display: block;
                            width: 100%;
                            height: auto;
                            @extend .trns;
                        }
                    }
                }
                &:hover{
                    width:184px;
                    height:184px;
                    &.itens{
                        border:7px solid $bgWhite;
                        figure{
                            border:7px solid $borderGray;
                        }
                    }
                }
                &.on{
                    width:184px;
                    height:184px;
                }
            }
        }
        &:hover{
            li{
                a.on{
                    width:106px;
                    height:106px;
                    .title{
                        img{
                            padding: 4px;
                        }
                    }
                    &:hover{
                        width:184px;
                        height:184px;
                        .title{
                            img{
                                padding: 7px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.highlights_bar{
    margin-bottom: 45px;
    &.products{
        margin-top: 15px;
        margin-bottom: 25px;
    }
    ul.high_list{
        li{
            a{
                display: block;
                figure{
                    height: 130px;
                    overflow: hidden;
                    img{
                        @extend .trn;
                        @extend .imgcover;
                    }
                }
                &:hover{
                    figure{
                        img{
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
}
.releases_bar{
    margin-bottom: 25px;
    .releases{
        width: 90%;
        .slick-arrow{
            width:38px;
            height:38px;
            top:calc(50% - 19px);
            position: absolute;
            @extend .replace;
            @include bg_img_pos(no-repeat, center, auto);
        }
        .slick-prev{
            left:-30px;
            background-image: url(../img/icons/arrow_left.png);
            @extend .trn;
            &:hover{
                opacity: 0.7;
            }
        }
        .slick-next{
            right:-30px;
            background-image: url(../img/icons/arrow_right.png);
            @extend .trn;
            &:hover{
                opacity: 0.7;
            }
        }
        a{
            @extend .trns;
            transform: scale(0.85);
            article{
                background-color: #626161;
                padding:10px 20px 18px 20px;
                width:330px;
                margin-left: auto;
                margin-right: auto;
                .header{
                    font-family: $greatVibesFont;
                    font-size: 36px;
                    text-align: center;
                    position: relative;
                }
                .info{
                    background-color: $bgWhite;
                    border: 2px solid #bababa;
                    border-radius: 3px;
                    position: relative;
                    figure{
                        height:200px;
                        img{
                            @extend .imgcontain;
                        }
                    }
                    .title{
                        font-size: 18px;
                        text-align: center;
                        background-color: #626161;
                        padding-top: 12px;
                        padding-bottom: 15px;
                        font-family: $futuraMdFont;
                        text-transform: uppercase;
                    }
                    .desc{
                        height: 60px;
                        padding:5px;
                        box-sizing: border-box;
                        p{
                            font-size: 16px;
                            color: #616161;
                            text-align: center;
                        }
                    }
                    .tag{
                        text-align: center;
                        margin-top: 15px;
                        margin-bottom: 5px;
                        span{
                            border-top: 1px solid #d3d3d3;
                            padding:3px 30px 8px 30px;
                            font-size: 18px;
                            color: #878787;
                            font-family: $futuraMdFont;
                        }
                    }
                    .releases_btn{
                        font-family: $futuraMdFont;
                        font-size: 14px;
                        position: absolute;
                        right: 2px;
                        bottom: 5px;
                        background-color: $bgRed;
                        height: 30px;
                        padding:0px 7px;
                    }
                }
            }
            &.slick-center{
                transform: scale(1);
                article{
                    background-color: #1c1c1c;
                    .header{
                        color: $colorRed;
                        span{
                            &::before, &::after{
                                @extend .beforeAfter;
                                width:42px;
                                height:42px;
                                top:0px;
                            }
                            &::before{
                                left:-50px;
                            }
                            &::after{
                                right:-50px;
                            }
                        }
                    }
                    .info{
                        .title{
                            background-color: $bgRed;
                        }
    
                    }
                }
            }
        }
    }
}

.insta_bar{
    background-color: $bgRed;
    padding:15px 20px 30px 20px;
    margin-bottom:35px;
    ul.list{
        margin-top: 5px;
        li{
            a{
                display: block;
                figure{
                    height: 200px;
                    background-color: #000;
                    img{
                        @extend .imgcover;
                    }
                }
            }
        }
    }
}


//  PRODUTOS
.products_page{
    background-color: $bgWhite;
    padding:20px 0px;
    margin-bottom: 45px;
    .breadcrumbs{
        background-color: #3c3c3c;
        padding:5px 65px 6px 40px;
        a, span{
            font-size: 16px;
            font-weight: 300;
            @extend .trn;
        }
        a:hover{
            color: $colorRed;
        }
    }
    .content{
        padding:20px 65px 0px 40px;
        .header{
            .title{
                font-size: 36px;
                color: #000000;
            }
            p{
                font-size: 18px;
                color: #444444;
            }
        }
        .filters{
            padding:8px 20px 8px 30px;
            background-color: #efefef;
            margin-top: 10px;
            align-items: center;
            p.category{
                font-size: 30px;
                color: #3c3c3c;
                text-transform: uppercase;
            }
            p.counter{
                font-size: 22px;
                color: #828282;
                span{
                    color: #4c4c4c;
                }
            }
            .label{
                font-size:16px;
                color: #828282;
                margin-left: 8px;
            }
            select{
                width:100%;
                max-width: 230px;
                background-color: #fff;
                height: 30px;
                border: 1px solid #ccc;
                border-radius: 3px;
                padding-left: 5px;
                margin-left: 3px;
                color: #444444;
                font-size: 14px;
                &.pagination{
                    width: 170px;
                }
            }
            ul.tabs{
                li{
                    margin-left: 2px;
                    margin-right: 2px;
                    a{
                        display: block;
                        width: 30px;
                        height: 30px;
                        border-radius: 8px;
                        border: 1px solid $bgRed;
                        color: $colorRed;
                        text-align: center;
                        box-sizing: border-box;
                        padding-top: 6px;
                        font-size: 14px;
                        @extend .trn;
                        &.on, &:hover{
                            color: $colorWhite;
                            background-color: $colorRed;
                        }
                    }
                }
            }
        }
        .products_box{
            grid-template-columns: 1fr 3fr;
            ul.categ_list{
                margin-top: 20px;
                padding-left: 30px;
                padding-right: 15px;
                li{
                    margin-top: 5px;
                    a{
                        font-size: 22px;
                        color: #828282;
                        @extend .trn;
                        &:hover, &.on{
                            color: $colorRed;
                        }
                    }
                    &.submenu{
                        a{
                            padding-left: 15px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        ul.products_list{
            padding-top: 20px;
            padding-bottom: 10px;
            grid-auto-rows: min-content;
            &.small{
                padding: 10px 10px 10px 10px;
                background-color: #f9f9f7;
                li{
                    background-color: #fff;
                    figure{
                        height: 160px;
                    }
                    h3.title{
                        font-size: 14px;
                    }
                    p.code{
                        font-size: 10px;
                    }
                    p.price{
                        font-size: 16px;
                    }
                }
            }
            li{
                padding:5px 0px 18px 0px;
                figure{
                    height: 175px;
                    border:1px solid #efefef;
                    margin-bottom: 10px;
                    overflow: hidden;
                    img{
                        @extend .imgcontain;
                        @extend .trn;
                        object-position: center;
                    }
                    &:hover{
                        img{
                            transform: scale(1.1);
                        }
                    }
                }
                h3.title{
                    text-align: center;
                    font-size: 16px;
                    font-weight: 300;
                    color: #777;
                    padding:0px 10px 0px 10px;
                    margin-bottom: 8px;
                }
                p.code{
                    text-align: center;
                    font-size: 12px;
                    color: #777;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                }
                p.price{
                    text-align: center;
                    font-size: 16px;
                    color: $colorRed;
                    margin-bottom: 2px;
                }
                p.installment{
                    text-align: center;
                    font-size: 14px;
                    color: #777;
                    font-weight: 300;
                }
                .see_more_btn{
                    width: 90px;
                    height: 28px;
                    background-color: $bgRed;
                    text-transform: uppercase;
                    font-size: 14px;
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 5px;
                    @extend .trn;
                    &:hover{
                        background-color: #ff0010;
                    }
                }
            }
            &.releases{
                padding-top: 25px;
                padding-bottom:100px;
                li{
                    border:0px none transparent;
                    position: relative;
                    z-index: 1;
                    .mask{
                        border:3px solid #2a2a2a;
                        background-color: #fff;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        left:0px;
                        top:0px;
                        @extend .trn;
                    }
                    .relt{
                        z-index: 5;
                        margin: 3px;
                    }
                    figure {
                        height: 230px;
                    }
                    h2{
                        text-align: center;
                        font-size: 24px;
                        color: #c2040f;
                        margin-bottom: 12px;
                    }
                    h3.title{
                        text-align: center;
                        font-size: 14px;
                        color: #2a2929;
                        padding:0px 15px 0px 15px;
                        margin-bottom: 15px;
                        font-weight: 400;
                    }
                    .btn_box{
                        width: 100%;
                        bottom:-65px;
                        opacity: 0;
                        visibility: hidden;
                        left:0px;
                        @extend .trn;
                        .see_more_btn{
                            width: 186px;
                            height: 45px;
                            background-color: $bgRed;
                            text-transform: uppercase;
                            font-size: 30px;
                            text-align: center;
                        }
                    }
                    &:hover{
                        z-index: 2;
                        .mask{
                            transform: scale(1.2, 1.2);
                            transform-origin: 50% 0px;
                        }
                        .btn_box{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        p.title_list{
            font-size: 22px;
            text-transform: uppercase;
            color: #3c3c3c;
            text-align: center;
            background-color: #f9f9f7;
            padding-top: 15px;
        }
    }
    .product{
        h2.title{
            background-color: #efefef;
            padding:10px 40px 20px 40px;
            text-align: center;
            font-size: 22px;
            color: #3c3c3c;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .main_info{
            padding:0px 35px;
            grid-template-columns: 54% calc(46% - 25px);
            .photos{
                grid-template-columns: 21% 79%;
                .thumbs{
                    max-height: 372px;
                    overflow-y: auto;
                    ul.list{
                        li{
                            cursor: pointer;
                            border-bottom:2px solid #efefef;
                            figure{
                                height:90px;
                                img{
                                    @extend .imgcontain;
                                    object-position: center;
                                }
                            }
                        }
                    }
                }
                .big{
                    figure{
                        height: 370px;
                        border:2px solid #efefef;
                        margin:0px 0px 0px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        &::after{
                            content:"Clique na imagem para ampliar";
                            right: 3px;
                            bottom: 2px;
                            position: absolute;
                            color: #777;
                            z-index: 888;
                        }
                        img{
                            height: auto;
                            width: auto;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
            .resume{
                padding-top: 40px;
                .code{
                    font-size: 14px;
                    color: #000;
                    margin-bottom: 10px;
                    font-weight: bold;
                }
                p{
                    font-size: 18px;
                    color: #525252;
                    font-weight: 300;
                }
                .price{
                    font-size: 14px;
                    color: #000;
                    margin-top: 25px;
                    margin-bottom: 20px;
                    font-weight: bold;
                }
                .more_detail_btn{
                    background-color: $bgRed;
                    padding:5px 10px 6px 10px;
                    font-size: 24px;
                    text-transform: uppercase;
                }
            }
        }
        .complete_info{
            background-color: #f2f2f2;
            padding:10px 35px 15px 35px;
            margin-top: 20px;
            .noborder{
                padding-left: 35px;
            }
            .border{
                border-right:1px solid #fff;
                padding-right: 35px;
            }
            h2{
                color: $colorRed;
                font-size: 22px;
                text-transform: uppercase;
                margin-bottom: 12px;
            }
            p{
                font-weight: 300;
                color: #525252;
                font-size: 14px;
            }
        }

    }
}

//  SOBRE
.products_page{
    .about{
        .main{
            padding:15px 0px 0px 40px;
            .text{
                padding-right: 30px;
                padding-bottom: 25px;
                h2{
                    font-size:45px;
                    color:#000;
                    margin-bottom: 8px;
                }
                p{
                    color: #444444;
                    font-size: 18px;
                }
            }
            .photo{
                margin-top: 45px;
                position: relative;
                img{
                    @extend .imgcover;
                    object-position: center;
                }
                .open_map_btn{
                    bottom:-35px;
                    right:10px;
                    width:130px;
                    height:105px;
                    border-radius: 10px;
                    text-align: center;
                    font-size: 18px;
                    padding: 37px 10px 0px 10px;
                    box-sizing: border-box;
                    background-color: #2a2929;
                    font-family: $futuraMdFont;
                    @include bg_img_pos(no-repeat, center 15px, auto);
                    background-image: url(../img/icons/map_white.png);
                }
            }
        }
        .data_numbers{
            background-color: $bgRed;
            padding:25px 120px 25px 30px;
            grid-template-columns: 24% 38% 38%;
            align-items: center;
            .item{
                padding:0px 20px;
                position: relative;
                .number{
                    font-size:52px;
                    font-weight: bold;
                    text-align: center;
                    font-family: $futuraMdFont;
                }
                .text{
                    font-size:28px;
                    font-weight: bold;
                    text-align: center;
                    font-family: $futuraMdFont;
                    color: #490005;
                    line-height: 110%;
                }
                &.center{
                    &::before, &::after{
                        @extend .beforeAfter;
                        width:3px;
                        height:100%;
                        background-color: #fff;
                        top:0%;
                    }
                    &::before{
                        left:-1px;
                    }
                    &::after{
                        right:-2px;
                    }
                }
            }
        }
        .testimonials{
            padding:30px 40px 30px 40px;
            .title{
                font-size: 45px;
                color: #000;
            }
            ul.list{
                margin-top: 25px;
                align-items: center;
                grid-column-gap: 80px;
                padding: 0px 60px;
                li{
                    font-family: $futuraMdFont;
                    font-size: 22px;
                    color: $colorRed;
                    text-align: center;
                }
            }
        }
    }

}

//  SOBRE e CONTATO
.products_page{
    .contact{
        .main{
            .info{
                padding:20px 70px 20px 40px;
                position: relative;
                h2{
                    font-size: 36px;
                    color: #000;
                    margin-bottom: 3px;
                }
                p{
                    font-size: 21px;
                    color: #444444;
                    i{
                        font-style:italic;
                    }
                }
                h3{
                    color: $colorRed;
                    font-size: 18px;
                    text-transform: uppercase;
                    margin-top: 30px;
                    font-family: $futuraBtFont;
                    font-weight: 300;
                    margin-bottom: 3px;
                }
                &::after{
                    @extend .beforeAfter;
                    width:1px;
                    height:90%;
                    background-color: #e2e2e2;
                    top:5%;
                    right:70px;
                }
            }
            .form{
                padding-bottom: 20px;
                h3{
                    color: $colorRed;
                    font-size: 18px;
                    text-transform: uppercase;
                    margin-top: 30px;
                    font-family: $futuraBtFont;
                    font-weight: 300;
                    margin-bottom: 5px;
                }
                form{
                    display: block;
                    width: 100%;
                    max-width: 340px;
                    padding-top: 5px;
                    position:relative;
                    .okform{
                        width:100%;
                        height:100%;
                        position:absolute;
                        left:0px;
                        top:0px;
                        background-color:#fff;
                        opacity:0;
                        visibility:hidden;
                        @extend .trn;
                        p{
                            font-size:22px;
                            text-align:center;
                            padding-top:80px;
                            color:#555;
                        }
                        &.on{
                            opacity:1;
                            visibility:visible;
                        }
                    }
                    .input_text{
                        width: 100%;
                        max-width: 270px;
                        height:30px;
                        font-size: 14px;
                        color: #a3a3a3;
                        padding-left: 10px;
                        border: 1px solid #a3a3a3;
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        display: block;
                    }
                    .textarea{
                        width: 100%;
                        height:90px;
                        font-size: 14px;
                        color: #a3a3a3;
                        padding-left: 10px;
                        padding-top: 10px;
                        border: 1px solid #a3a3a3;
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        display: block;
                        resize: none;
                    }
                    .submit{
                        width: 130px;
                        height:30px;
                        font-size: 14px;
                        color: #787777;
                        text-align: center;
                        border: 1px solid #a3a3a3;
                        box-sizing: border-box;
                        background-color: #e1e1e1;
                        font-weight: 900;
                        margin-top: 5px;
                    }
                }
            }
        }
        .map_box{
            background-color: #c3c3c3;
            padding:20px;
            margin:0px 25px 30px 25px;
            .text{
                h3{
                    font-size: 18px;
                    color: $colorRed;
                    text-transform: uppercase;
                    padding:5px 0px 10px 25px;
                    font-family: $futuraBtFont;
                    font-weight: 300;
                    @include bg_img_pos(no-repeat, left top, auto);
                    background-image: url(../img/icons/local_red.png);
                }
                p{
                    font-size: 24px;
                    color: #444444;
                    i{
                        font-style:italic;
                    }
                }
            }
            .map{
                margin-top: 10px;
                height:500px;
                iframe{
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
        .payments{
            padding-left: 40px;
            padding-bottom: 20px;
            h3{
                font-size: 18px;
                color: $colorRed;
                text-transform: uppercase;
                font-family: $futuraBtFont;
                font-weight: 300;
            }
            figure{
                padding-top: 15px;
                img{
                    max-width: 100%;
                }
            }
        }
    }
}

@import "common/footer";

@media (max-width: 1299px){
    .insta_bar ul.list li a figure {
        height: 175px;
    }
    .container {
        max-width: 1000px;
    }
    .timer_banner{
        .left .text .main {
            font-size: 22px;
        }
        .right .time_box .item {
            height: 64px;
            width: 64px;
            .number {
                font-size: 26px;
            }
        }
    }
    .tabs_banner .item{
        .tab{
            .number {
                font-size: 42px;
                padding-top: 10px;
            }
            .title h3 {
                font-size: 32px;
            }
        }
        .content{
            .header p {
                font-size: 36px;
                padding-top: 10px;
            }
            .info{
                .left p {
                    font-size: 20px;
                }
                .right p {
                    font-size: 16px;
                }
            }
        }
    }
    .best_sellers_bar ul.best_sell_list{
        li a {
            width: 90px;
            height: 90px;
            &:hover, &.on, &.on:hover{
                width: 164px;
                height: 164px;
            }
        }
        &:hover{
            li a.on {
                width: 90px;
                height: 90px;
                &:hover {
                    width: 164px;
                    height: 164px;
                }
            }
        }
    }
    .releases_bar .releases a article {
        padding: 10px 10px 10px;
        width: 300px;
        .info .title {
            font-size: 16px;
        }
    }
    
}

@media (max-width: 1169px){
    .insta_bar ul.list li a figure {
        height: 135px;
    }
    .timer_banner{
        height: auto;
        padding-top: 5px;
        padding-bottom: 15px;
        position: relative;
        overflow: hidden;
        .left {
            display: block;
            .text{
                padding-left: 180px;
                .main {
                    text-align: left;
                    span {
                        font-size: 32px;
                    }
                }
                .secondary {
                    text-align: left;
                }
            }
            figure {
                position: absolute;
                margin-left: 30px;
                max-height: none;
                width: 110px;
                bottom: -5px;
                img{
                    max-width: 100%;
                }
            }
        }
        .right {
            grid-template-columns: 245px calc(100% - 245px);
            padding-left: 180px;
            padding-top: 10px;
            .time_box .item {
                height: 56px;
                width: 56px;
                padding-top: 6px;
                .text {
                    margin-top: -3px;
                }
            }
        }
    }
    .tabs_banner {
        margin-left: 15px;
        margin-right: 15px;
        .item .content{
            .header {
                height: 50px;
                p {
                    font-size: 24px;
                    padding-top: 10px;
                }
            }
            .info {
                height: calc(100% - 50px);
                grid-template-columns: 1fr 1fr;
                padding-top: 10px;
                padding-bottom: 10px;
                .left p {
                    font-size: 18px;
                }
                .center figure img {
                    max-width: 80%;
                }
                .right{
                    grid-column: span 2;
                }
            }
        }
    }
    .best_sellers_bar ul.best_sell_list{
        min-height: 130px;
        li{
            a{
                width: 70px;
                height: 70px;
                figure.title img {
                    padding: 3px;
                }
                &.on, &.on:hover, &:hover{
                    width: 120px;
                    height: 120px;
                }
                &.itens {
                    border: 2px solid #fff;
                }
                &:hover.itens {
                    border: 3px solid #fff;
                }
                &:hover.itens figure {
                    border: 3px solid #606060;
                }
                &.on:hover {
                    width: 120px;
                    height: 120px;
                    .title img {
                        padding: 3px;
                    }
                }
            }
        }
    }
    .highlights_bar {
        margin-left: 15px;
        margin-right: 15px;
        ul.high_list li a figure {
            height: 90px;
        }
    }
    .releases_bar .releases a article {
        width: 240px;
        .header {
            font-size: 28px;
        }
        .info{
            .desc {
                height: auto;
            }
            .title {
                font-size: 14px;
            }
            figure {
                height: 160px;
            }
        }
    }
    .products_page .about{
        .main {
            padding: 15px 0 0 40px;
            grid-template-columns: 60% 40%;
        }
        .data_numbers {
            padding: 45px 30px 45px 30px;
            .item{
                .number {
                    font-size: 42px;
                }
                .text {
                    font-size: 22px;
                }
            }
        }
    }
    .products_page .about .testimonials ul.list {
        grid-column-gap: 40px;
        padding: 0 0px;
        li {
            font-size: 18px;
        }
    }
    .products_page{
        .breadcrumbs {
            padding: 5px 15px 6px 15px;
        }
        .about .main {
            padding: 15px 0 0 15px;
        }
        .contact{
            .main{
                .info {
                    padding: 20px 0px 20px 15px;
                    &::after {
                        right: 0px;
                    }
                    h2 {
                        font-size: 32px;
                        margin-bottom: 10px;
                        line-height: 100%;
                    }
                }
                .form {
                    padding-left: 20px;
                }
            }
            .map_box .map {
                margin-top: 10px;
                height: 350px;
            }
        }
        .content {
            padding: 20px 15px 0 15px;
            .filters {
                padding: 8px 15px 8px 15px;
                p.category {
                    font-size: 26px;
                }
                p.counter {
                    font-size: 22px;
                }
            }
            .products_box ul.categ_list {
                padding-left: 15px;
            }
            ul.products_list{
                li {
                    padding: 5px 0 10px;
                }
                &.releases li{
                    .btn_box .see_more_btn {
                        font-size: 24px;
                    }
                    .mask {
                        border: 2px solid #2a2a2a;
                    }
                }
            }
        }
    }
    .products_page .product .main_info{
        .photos{
            .big figure {
                height: 250px;
            }
            .thumbs {
                max-height: 250px;
            }
        }
        .resume {
            padding-top: 20px;
            .more_detail_btn {
                font-size: 22px;
            }
        }
    }
    
    

}

@media (max-width: 1023px){
    .fullbox .container {
        background-size: 400px auto;
        .welcome_text{
            img {
                max-width: 350px;
            }
            .loginbtn {
                max-width: 240px;
            }
        }
    }
}

@media (max-width: 767px){
    body.mobilescroll{
        overflow: hidden;
    }
    .insta_bar ul.list li a figure {
        height: 110px;
    }
    .timer_banner{
        .left{
            display: grid;
            grid-template-columns: 110px calc(100% - 110px);
            .text {
                padding-left: 0px;
            }
            figure {
                position: relative;
                margin-left: 0px;
                width: auto;
                img{
                    max-width: 80%;
                }
            }
        }
        .right {
            padding-left: 10px;
            padding-top: 15px;
            grid-column-gap: 10px;
            .time_button {
                padding: 10px 15px;
                font-size: 16px;
            }
        }
    }
    .tabs_banner {
        flex-wrap: wrap;
        padding-top: 25px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        .item {
            width: 100%;
            height: auto;
            margin-left: 0px;
            margin-top: -18px;
            z-index: 10 !important;
            .content {
                margin-left: 0px;
                text-align: center;
                padding-bottom: 30px;
                background-color: #fff;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                overflow: hidden;
                .header {
                    padding-left: 0px;
                    border-top-right-radius: 0px;
                }
                .info {
                    height: auto;
                    padding-right: 10px;
                    .left p {
                        font-size: 18px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
                .tab_plus_btn {
                    position: relative;
                    right: auto;
                    bottom: auto;
                    padding: 6px 25px 8px;
                    font-size: 22px;
                    background-color: #a2030d;
                }
            }
            .tab {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 0px;
                height: 80px;
                padding-left: 0px;
                display: flex;
                justify-content: center;
                border: 0px none transparent;
                .title h3 {
                    font-size: 26px;
                    transform: none;
                    text-align: left;
                    margin-top: 0px;
                    order: 1;
                }
                .number {
                    font-size: 32px;
                    order: 2;
                    padding-left: 10px;
                }
            }
        }
    }
    .best_sellers_bar {
        margin-bottom: 30px;
        ul.best_sell_list {
            justify-content: center;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            padding-top: 5px;
            padding-bottom: 5px;
            li{
                &.main{
                    grid-column: span 4;
                }
                display: flex;
                justify-content: center;
                a{
                    width: 70px !important;
                    height: 70px !important;
                }
            }
        }
    }
    .releases_bar .releases .slick-prev {
        left: 0px;
    }
    .releases_bar .releases .slick-next {
        right: 0px;
    }
    .insta_bar ul.list li + li + li + li{
        display: none;
    }
    .mob_span-2{
        grid-column: span 2;
    }
    .products_page{
        .content{
            .filters{
                ul.tabs.fr {
                    float: none;
                    li {
                        margin-left: 0px;
                        margin-right: 4px;
                    }
                }
                p.counter {
                    font-size: 18px;
                }
                select.pagination {
                    width: 140px;
                    margin-left: 0px;
                }
            }
            .products_box {
                grid-template-columns: 1fr;
                ul.categ_list {
                    display: none;
                }
            }
            ul.products_list.releases li{
                border: 2px solid #2a2a2a;
                .mask{
                    display: none;
                }
                .btn_box {
                    bottom: auto;
                    opacity: 1;
                    visibility: visible;
                    left: auto;
                    position: relative;
                    .see_more_btn {
                        width: 90%;
                        height: 40px;
                    }
                }
            }
        }
        .product{
            .main_info {
                padding: 0 15px;
                display: flex;
                flex-wrap: wrap;
                .photos {
                    order: 2;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    .thumbs {
                        height: 85px;
                        overflow-x: auto;
                        ul.list{
                            display: flex;
                            flex-wrap: nowrap;
                            li{
                                border-bottom: 0px none transparent;
                                figure {
                                    height: 80px;
                                    min-width: 100px;
                                    margin-left: 2px;
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                    .big{
                        width: 100%;
                        figure {
                            height: 200px;
                        }
                    }
                }
                .resume {
                    order: 1;
                    width: 100%;
                }
            }
            .complete_info {
                padding: 10px 15px 15px;
                .border {
                    border-right: 0px none transparent;
                    padding-right: 0px;
                    border-bottom: 1px solid #fff;
                    padding-bottom: 20px;
                    margin-bottom: 10px;
                }
                .noborder{
                    padding-left: 0px;
                }
            }
        }
        .about{
            .main {
                padding: 15px 15px 0 15px;
                grid-template-columns: 1fr;
                .text {
                    padding-right: 0px;
                }
                .photo {
                    margin-top: 0px;
                }
            }
            .data_numbers {
                padding: 25px 15px;
                grid-template-columns: 1fr;
                .item.center {
                    border-bottom: 1px solid #fff;
                    border-top: 1px solid #fff;
                    padding-top: 15px;
                    margin-top: 10px;
                    padding-bottom: 15px;
                    margin-bottom: 10px;
                    &::before, &::after{
                        display: none;
                    }
                }
            }
            .testimonials {
                padding: 30px 15px;
                ul.list li {
                    margin-bottom: 30px;
                }
            }
        }
        .contact{
            .main{
                .info {
                    padding: 20px 15px 20px 15px;
                    &::after {
                        display: none;
                    }
                }
                .form h3 {
                    margin-top: 20px;
                }
            }
            .map_box{
                padding: 15px;
                margin: 0 15px 30px;
                .text p {
                    font-size: 20px;
                }
                .map {
                    height: 250px;
                }
            }
            .payments {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .none_mob{
        display: none;
    }
    .block_mob{
        display: block;
    }
    .nofloat_mob{
        float: none;
    }
    .whats_float {
        right: 95px;
        bottom: 20px;
        width: 65px;
        height: 65px;
        background-size: 35px auto;
    }
    .fullbox .container {
        background-size: 400px auto;
        background-position: -180px bottom;
        .welcome_text{
            padding-right: 20px;
            img {
                max-width: 70%;
                margin-left: auto;
            }
            .loginbtn {
                margin-left: auto;
                max-width: 50%;
            }
        }
    }

}

@media (max-width: 399px){
   
}