//      SASS MIXINs

//		Transition
@mixin transition($property) {
	-webkit-transition:$property;
	-moz-transition:$property;
	-o-transition:$property;
	transition:$property;
}

//		TRANSLATE
@mixin translate($vx, $vy) {
	-moz-transform: translate($vx,$vy);
	-o-transform: translate($vx,$vy);
	-ms-transform: translate($vx,$vy);
	-webkit-transform: translate($vx,$vy);
	transform: translate($vx,$vy);
}

//		BACKGROUND IMAGE POSITION
@mixin bg_img_pos($repeat, $position, $size) {
	background-repeat:$repeat;
	background-position:$position;
	background-size:$size;
}


//		NORMALIZE / RESET
*{
	margin:0px;
	padding:0px;
	color:inherit;
	font-size:inherit;
	font-family:inherit;
	font-weight:inherit;
    line-height: inherit;
	background-color:transparent;
	border:0px none transparent;
	text-align:left;
}
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
html,body{
	color:#fff;
	font-size:12px;
	font-family:$defaultFont,Calibri,Arial,Helvetica,sans-serif;
	font-weight:normal;
    line-height: normal;
}
body{
	background-color:#000;
	overflow:auto;
	margin:0px;
}
header, section, footer, aside, nav, article, figure, details, figcaption, hgroup, main, menu, summary, address{
	display: block;
}
address{
	font-style:normal;
}
a {
    background-color: transparent;
}
a, a:hover, a:active{
	text-decoration:none;
    outline: 0;
    color:inherit;
}
strong, b{
	font-weight:bold;
}
ul{
	li{
		list-style:none;
	}
}
img {
    border: 0;
}
pre {
    overflow: auto;
}
code, kbd, pre, samp {
    font-family: monospace, monospace;
    font-size: 12px;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
button, input[type="button"], input[type="submit"], input[type="reset"]{
    cursor:pointer;
    -webkit-appearance: button;
}
button[disabled], input[disabled] {
    cursor: default;
}
button, input, textarea, a{
	outline:none !important;
	box-shadow:none !important;
}
input, button{
	border:0px none transparent;
	background-color:transparent;
}
button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input {
    line-height: normal;
}
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
legend {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td, th {
    padding: 0;
}
::-webkit-input-placeholder {
    color:inherit;
}
:-moz-placeholder {
    color:inherit;
    opacity:1;
}
::-moz-placeholder {
    color:inherit;
    opacity:1;
}
:-ms-input-placeholder {
    color:inherit;
}
.tiny{
	ul{
		li{
			list-style: disc inside;
		}
	}
}
button, input, textarea, a{
    box-shadow: none !important;
    outline: none !important;
    appearance: none;
}


//      DEFAULT

.auto{
	margin-left:auto;
	margin-right:auto;
	float:none;
}
.floatleft, .fl{
	float:left;
}
.floatright, .fr{
	float:right;
}
.absolute, .abs{
	position:absolute;
}
.relative, .relt{
	position:relative;
}
.fixed{
	position:fixed;
}
.z10{
	z-index:10;
}
.z20{
	z-index:20;
}
.z30{
	z-index:30;
}
.both{
	clear:both;
}
.width100, .w100{
	width:100%;
}
.width50, .w50{
	width:50%;
}
.height100, .h100{
	height:100%;
}
.height50, .h50{
	height:50%;
}
.vh100{
	height:100vh
}
.vh50{
	height:50vh;
}
.none{
	display:none;
}
.noneimp{
	display:none !important;
}
.hidden, .hdn{
	overflow:hidden;
}
.pointer{
	cursor:pointer;
}
.aligncenter, .alcenter{
	text-align:center;
}
.replace{
	text-indent: 102%;
	white-space: nowrap;
	overflow: hidden;
}
.transition, .trn{
	@include transition(all 0.3s);
}
.transitionslow, .trns{
	@include transition(all 0.5s);
}
.transitionfast, .trnf{
	@include transition(all 0.15s);
}
.middle{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}
.menu_burger_button{
    width:30px;
	span{
		display:block;
		background-color:#cccccc;
		height:4px;
		margin-bottom:7px;
		@include transition(all 0.3s);
	}
}
.menu_burger_button:hover{
	span{
		background-color:#000000;
	}
}
.menu_burger_close{

}
.menu_burger{
	width:100%;
	height:100%;
	position:fixed;
	z-index:999;
	top:0px;
    left:0px;
    background-color:#dddddd;
	@include transition(all 0.5s);
}
.menu_burger.vertical{
	@include translate(0%,-100%);
}
.menu_burger.vertical.bottom{
	@include translate(0%,100%);
}
.menu_burger.horizontal{
	@include translate(-100%,0%);
}
.menu_burger.horizontal.right{
	@include translate(100%,0%);
}
.menu_burger.on, .menu_burger.on{
    -moz-transform: translate(0%,0%) !important;
    -o-transform: translate(0%,0%) !important;
    -ms-transform: translate(0%,0%) !important;
    -webkit-transform: translate(0%,0%) !important;
    transform: translate(0%,0%) !important;
}
.flex{
	display: -webkit-box; 
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex; 
	display: flex;
}
.grid{
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    &.template-2, &.tpl-2{
        grid-template-columns: 1fr 1fr;
    }
    &.template-3, &.tpl-3{
        grid-template-columns: 1fr 1fr 1fr;
    }
    &.template-4, &.tpl-4{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    &.template-5, &.tpl-5{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    &.template-6, &.tpl-6{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    &.colgap-0, &.cgap-0{
        grid-column-gap: 0px;
    }
    &.colgap-10, &.cgap-10{
        grid-column-gap: 10px;
    }
    &.colgap-15, &.cgap-15{
        grid-column-gap: 15px;
    }
    &.colgap-20, &.cgap-20{
        grid-column-gap: 20px;
    }
    &.colgap-25, &.cgap-25{
        grid-column-gap: 25px;
    }
    &.colgap-30, &.cgap-30{
        grid-column-gap: 30px;
    }
    &.rowgap-15, &.rgap-15{
        grid-row-gap: 15px;
    }
    &.rowgap-20, &.rgap-20{
        grid-row-gap: 20px;
    }
    &.rowgap-25, &.rgap-25{
        grid-row-gap: 25px;
    }
    &.rowgap-30, &.rgap-30{
        grid-row-gap: 30px;
    }
    .colspan-2{
        grid-column: span 2;
    }
    .colspan-3{
        grid-column: span 3;
    }
    .colspan-4{
        grid-column: span 4;
    }
    .colspan-5{
        grid-column: span 5;
    }
    .colspan-6{
        grid-column: span 6;
    }
    .rowspan-2{
        grid-row: span 2;
    }
    .rowspan-3{
        grid-row: span 3;
    }
    .rowspan-4{
        grid-row: span 4;
    }
    .rowspan-5{
        grid-row: span 5;
    }
    .rowspan-6{
        grid-row: span 6;
    }
}
.whats_float{
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 50px;
    right: 28px;
    z-index: 20;
    @include bg_img_pos(no-repeat, calc(50% + 1px) 50%, 30px auto);
    //background-image: url(../img/temp/whats.png);
    background-image: url(../img/svg/whats.svg);
    background-color: #25D366;
    border-radius: 50%;
    @extend .trn;
    &:hover{
        background-color: #56ED88;
    }
}
.beforeAfter{
    content:"";
    position: absolute;
}
.imgcover{
    object-fit: cover;
    object-position: center;
    width:100%;
    height:100%;
}
.imgcontain{
    object-fit: contain;
    object-position: center;
    width:100%;
    height:100%;
}
/*		DEFAULT CSS			*/












/*		MEDIA QUERIES		*/
@media (max-width: 1920px){}
@media (max-width: 1299px){
    .whats_float {
    }
}
@media (max-width: 1169px){
    .grid{
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        &.template_tab, &.tpl_tab{
            grid-template-columns: 1fr;
        }
        &.template_tab-2, &.tpl_tab-2{
            grid-template-columns: 1fr 1fr;
        }
        &.template_tab-3, &.tpl_tab-3{
            grid-template-columns: 1fr 1fr 1fr;
        }
        &.template_tab-4, &.tpl_tab-4{
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        &.template_tab-5, &.tpl_tab-5{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
        &.template_tab-6, &.tpl_tab-6{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
        &.colgap_tab-0, &.cgap_tab-0{
            grid-column-gap: 0px;
        }
        &.colgap_tab-10, &.cgap_tab-10{
            grid-column-gap: 10px;
        }
        &.colgap_tab-15, &.cgap_tab-15{
            grid-column-gap: 15px;
        }
        &.colgap_tab-20, &.cgap_tab-20{
            grid-column-gap: 20px;
        }
        &.colgap_tab-25, &.cgap_tab-25{
            grid-column-gap: 25px;
        }
        &.colgap_tab-30, &.cgap_tab-30{
            grid-column-gap: 30px;
        }
        &.rowgap_tab-0, &.rgap_tab-0{
            grid-row-gap: 0px;
        }
        &.rowgap_tab-15, &.rgap_tab-15{
            grid-row-gap: 15px;
        }
        &.rowgap_tab-20, &.rgap_tab-20{
            grid-row-gap: 20px;
        }
        &.rowgap_tab-25, &.rgap_tab-25{
            grid-row-gap: 25px;
        }
        &.rowgap_tab-30, &.rgap_tab-30{
            grid-row-gap: 30px;
        }
        .colspan_tab-2{
            grid-column: span 2;
        }
        .colspan_tab-3{
            grid-column: span 3;
        }
        .colspan_tab-4{
            grid-column: span 4;
        }
        .colspan_tab-5{
            grid-column: span 5;
        }
        .colspan_tab-6{
            grid-column: span 6;
        }
        .rowspan_tab-2{
            grid-row: span 2;
        }
        .rowspan_tab-3{
            grid-row: span 3;
        }
        .rowspan_tab-4{
            grid-row: span 4;
        }
        .rowspan_tab-5{
            grid-row: span 5;
        }
        .rowspan_tab-6{
            grid-row: span 6;
        }
    }
    
}

@media (max-width: 767px){
    .whats_float {
        bottom: 40px;
        right: 10px;
    }
    .grid{
        &.template_mob, &.tpl_mob{
            grid-template-columns: 1fr;
        }
        &.template_mob-2, &.tpl_mob-2{
            grid-template-columns: 1fr 1fr;
        }
        &.template_mob-3, &.tpl_mob-3{
            grid-template-columns: 1fr 1fr 1fr;
        }
        &.template_mob-4, &.tpl_mob-4{
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        &.template_mob-5, &.tpl_mob-5{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
        &.template_mob-6, &.tpl_mob-6{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
        &.colgap_mob-0, &.cgap_mob-0{
            grid-column-gap: 0px;
        }
        &.colgap_mob-10, &.cgap_mob-10{
            grid-column-gap: 10px;
        }
        &.colgap_mob-15, &.cgap_mob-15{
            grid-column-gap: 15px;
        }
        &.colgap_mob-20, &.cgap_mob-20{
            grid-column-gap: 20px;
        }
        &.colgap_mob-25, &.cgap_mob-25{
            grid-column-gap: 25px;
        }
        &.colgap_mob-30, &.cgap_mob-30{
            grid-column-gap: 30px;
        }
        &.rowgap_mob-0, &.rgap_mob-0{
            grid-row-gap: 0px;
        }
        &.rowgap_mob-15, &.rgap_mob-15{
            grid-row-gap: 15px;
        }
        &.rowgap_mob-20, &.rgap_mob-20{
            grid-row-gap: 20px;
        }
        &.rowgap_mob-25, &.rgap_mob-25{
            grid-row-gap: 25px;
        }
        &.rowgap_mob-30, &.rgap_mob-30{
            grid-row-gap: 30px;
        }
        .colspan_mob-2{
            grid-column: span 2;
        }
        .colspan_mob-3{
            grid-column: span 3;
        }
        .colspan_mob-4{
            grid-column: span 4;
        }
        .colspan_mob-5{
            grid-column: span 5;
        }
        .colspan_mob-6{
            grid-column: span 6;
        }
        .rowspan_mob-2{
            grid-row: span 2;
        }
        .rowspan_mob-3{
            grid-row: span 3;
        }
        .rowspan_mob-4{
            grid-row: span 4;
        }
        .rowspan_mob-5{
            grid-row: span 5;
        }
        .rowspan_mob-6{
            grid-row: span 6;
        }
    }
    .whats_float {
    }
    
}